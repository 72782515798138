import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";

function SignUpButton() {
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [width]);

  const handleScroll = () => {
    if (width > 750 && window.location.href.includes("users/sign_in"))
      window.location.href = "/users/sign_up";
    else {
      document
        .getElementsByClassName("sign-up-section")[0]
        .scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      {width > 750 && (
        <button className="btn btn-primary" onClick={handleScroll}>
          Sign up
        </button>
      )}
    </div>
  );
}

document.addEventListener("DOMContentLoaded", () => {
  const mountPoint = document.getElementById("sign-up-button-mount-point");

  if (mountPoint) {
    const { mobileView } = mountPoint.dataset;

    ReactDOM.createRoot(mountPoint).render(
      <SignUpButton mobileView={mobileView} />
    );
  }
});
